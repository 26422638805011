<template>
  <div>
    <button type="button" @click="handlePrintjs">
      Print
    </button>

    <div id="print-con" :ref="refPrint">
      <p class="title" style="font-size: 24px;">列车编组顺序表(运统1)</p>
      <div class="head">
        <div class="l-con">
          <div class="list">
            <label>
              <span class="name">编组站:</span>
              <span class="line">三水西</span>
            </label>
            <label>
              <span class="name">终至站:</span>
              <span class="line">三眼桥</span>
            </label>
            <label>
              <span class="name">经由站:</span>
              <span class="line"></span>
            </label>
            <label>
              <span class="line">2021年02月20日09时55分</span>
            </label>
            <label>
              <span class="line">43210</span>
              <span class="name">次列车</span>
            </label>
          </div>

          <div class="list">
            <label>
              <span class="name">股&nbsp;&nbsp;&nbsp;道:</span>
              <span class="line">X04:</span>
            </label>
            <label>
              <span class="name">制表人:</span>
              <span class="line">三水西车号</span>
            </label>
            <label>
              <span class="name">检查人:</span>
              <span class="line">三水西</span>
            </label>
            <label>
              <span class="name">纸质票:</span>
              <span class="line"></span>
            </label>
            <label>
              <span class="name">NO.ONQ21220209</span>
            </label>
          </div>
        </div>
        <div class="r-con">
          <div class="qrcode"></div>
        </div>
      </div>
      <div class="body">
        <table>
          <tr>
            <th>顺序</th>
            <th>车种</th>
            <th>油种</th>
            <th>车号</th>
            <th>自重</th>
            <th>换长</th>
            <th>载重</th>
            <th>到站</th>
            <th>品名</th>
            <th>发站</th>
            <th>篷布</th>
            <th>票据号</th>
            <th>收货人</th>
            <th>属性</th>
            <th>记事</th>
          </tr>
          <tr v-for="(it, key) in dataList" :key="key">
            <td>{{ it.no }}</td>
            <td>{{ it.cz }}</td>
            <td>{{ it.yz }}</td>
            <td>{{ it.ch }}</td>
            <td>{{ it.zz }}</td>
            <td>{{ it.hc }}</td>
            <td>{{ it.zaiz }}</td>
            <td>{{ it.dz }}</td>
            <td>{{ it.pm }}</td>
            <td>{{ it.fz }}</td>
            <td>{{ it.pb }}</td>
            <td>{{ it.pjh }}</td>
            <td>{{ it.shr }}</td>
            <td>{{ it.sx }}</td>
            <td>{{ it.js }}</td>
          </tr>
        </table>
        
      </div>
    </div>
  </div>
</template>

<script>
import printJS from "print-js";
import { reactive, toRefs, onMounted, nextTick, onUnmounted } from "vue";

export default {
  name: "su-Printjs",
  components: {},
  props: {},
  setup(props, context) {
    const state = reactive({
      domPrint: "",
      dataList: [],
    });

    const refPrint = (el) => (state.domPrint = el);


    const initData = () => {
        console.log("XXXXX")
        let num = 50;
        for (let i = 0; i <= num; i ++) {
            state.dataList.push({
                no: i,
                cz: `C70-${i}`,
                yz: "",
                ch: "1512090",
                zz: "22.4",
                hc: "1.3",
                zaiz: "64",
                dz: "三眼桥",
                pm: "钢板",
                fz: "东海岛",
                pb: "",
                pjh: "WPQZA004175",
                shr: "广东乐居商",
                sx: "联",
                js: "",
            })
        }
    }

    const handlePrintjs = () => {
      //   printJS("print-con", "html", ["*"]);
      let ss = state.domPrint.innerHTML;
      console.log("XXXXX", ss);
      printJS({
        printable: "print-con",
        type: "html",
        font_size: "",
        targetStyles: ["*"],
      });
    };

    onMounted(() => {
        initData();
    });

    onUnmounted(() => {});

    return {
      ...toRefs(state),
      initData,
      handlePrintjs,
      refPrint,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
